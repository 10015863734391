export default [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home') 
  },
  {
    path: '/legal',
    name: 'Legal Notice',
    component: () => import('@/views/Markdown'),
    props: {
      file: 'legal.md'
    }
  },
  {
    path: '/privacy',
    name: 'Privacy Policy',
    component: () => import('@/views/Markdown'),
    props: {
      file: 'privacy.md'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/NotFound')
  },
]
