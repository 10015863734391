import { createApp } from 'vue'
import App           from './App.vue'
import Markdown      from './plugins/vue3-markdown-it'
import router        from './router'
import * as mdb      from 'mdb-vue-ui-kit'

const app = createApp(App)

Object.values(mdb).forEach((c) => {
  if (!c.name) return
  app.component(c.name, c)
})

app.component('Markdown', Markdown)

app.use(router)

app.mount('#app')
