import {
  createRouter,
  createWebHistory
} from 'vue-router'

import NProgress from 'nprogress'
import routes    from './routes'

import 'nprogress/nprogress.css'

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  document.title = `${to.name} - Lowlevel Studios`
  next()
})

router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start()
  }
  next()
})

router.afterEach((to, from) => {
  NProgress.done()
})

export default router
